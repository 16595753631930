import React, { memo, useMemo } from "react";
import TableChair from "./TableChair";

const oppositeDirections = {
  top: 'bottom',
  bottom: 'top',
  left: 'right',
  right: 'left',
};

const sideRotate = {
  top: 0,
  bottom: 180,
  left: 270,
  right: 90,
};

const getChairPosition = (offset, i, chairWidth, maxChairWidth, totalWidth, numberOfChairs) => {
  if (chairWidth < maxChairWidth) {
    return offset + i * (chairWidth + offset) + chairWidth / 2;
  } else {
    return offset + i * ((totalWidth - numberOfChairs * chairWidth) / (numberOfChairs - 1) + chairWidth) + chairWidth / 2;
  }
}

const RectangularTableChairs = ({ table, isSelected }) => {
  const maxChairs = (table.width / 16 + table.height / 16 - 2) * 2;

  const positions = useMemo(() => {
    if (table.variables?.manualChairs) {
      return {
        top: {
          number: table.variables?.chairsTop || 0,
          couch: table.variables?.chairsTopCouch || false,
        },
        right: {
          number: table.variables?.chairsRight || 0,
          couch: table.variables?.chairsRightCouch || false,
        },
        bottom: {
          number: table.variables?.chairsBottom || 0,
          couch: table.variables?.chairsBottomCouch || false,
        },
        left: {
          number: table.variables?.chairsLeft || 0,
          couch: table.variables?.chairsLeftCouch || false,
        },
      };
    }

    const w = table.width / 16;
    const h = table.height / 16;
    const n = table.seats;

    const positions = {
      top: { number: 0 },
      left: { number: 0 },
      right: { number: 0 },
      bottom: { number: 0 },
    };

    let previousPosition;
    const biggerSide = w >= h ? "top" : "left";
    const lesserSide = w < h ? "top" : "left";

    if (w >= h) {
      positions.top.number++;
      previousPosition = "top";
    } else {
      positions.left.number++;
      previousPosition = "left";
    }

    const conditionArray = [maxChairs, ...[...Array(maxChairs).keys()].slice(1)];
    for (let i = 1; i < conditionArray[n % maxChairs]; i++) {
      if (i % 2 > 0) {
        positions[oppositeDirections[previousPosition]].number++;
      } else {
        if (
          w >= h
            ? (w - 1 - positions.top.number) / (h - 1 - positions.left.number) >= 2
            : (h - 1 - positions.left.number) / (w - 1 - positions.top.number) >= 2
        ) {
          positions[biggerSide].number++;
          previousPosition = biggerSide;
        } else {
          positions[lesserSide].number++;
          previousPosition = lesserSide;
        }
      }
    }

    if (n > maxChairs) {
      const multiplier = Math.floor(n / maxChairs);
      positions.top.number += (w - 1) * multiplier;
      positions.bottom.number += (w - 1) * multiplier;
      positions.left.number += (h - 1) * multiplier;
      positions.right.number += (h - 1) * multiplier;
    }

    return positions;
  }, [table, maxChairs]);

  const chairs = [];

  Object.entries(positions).forEach(([side, config]) => {
    if (config.couch) {
      chairs.push(
        <TableChair
          key={`${table.id}-couch-${side}`}
          selected={isSelected}
          width={["top", "bottom"].includes(side) ? table.width - 6 : table.height - 6}
          x={side === "left" ? -6 : side === "right" ? table.width + 6 : table.width / 2}
          y={side === "top" ? -6 : side === "bottom" ? table.height + 6 : table.height / 2}
          rotation={sideRotate[side]}
        />
      );
    } else {
      const offset = 4;
      const totalWidth = (["top", "bottom"].includes(side) ? table.width : table.height) - offset * 2;
      const minSpacing = offset * (config.number - 1);
      const maxChairWidth = 24;
      const chairWidth = Math.min(maxChairWidth, (totalWidth - minSpacing) / config.number);

      for (let i = 0; i < config.number; i++) {
        chairs.push(
          <TableChair
            key={`${table.id}-chair-${side}-${i}`}
            selected={isSelected}
            width={chairWidth}
            x={
              ["top", "bottom"].includes(side)
                ? config.number === 1
                  ? table.width / 2
                  : getChairPosition(offset, i, chairWidth, maxChairWidth, totalWidth, config.number)
                : side === "left"
                  ? -6
                  : table.width + 6
            }
            y={
              ["left", "right"].includes(side)
                ? config.number === 1
                  ? table.height / 2
                  : getChairPosition(offset, i, chairWidth, maxChairWidth, totalWidth, config.number)
                : side === "top"
                  ? -6
                  : table.height + 6
            }
            rotation={sideRotate[side]}
          />
        );
      }
    }
  });

  return chairs;
};

export default memo(RectangularTableChairs);
