import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import StarRating from '@components/display/StarRating/StarRating';
import Colors from '@data/enums/Color.enum';
import SvgIcon from '@components/display/SvgIcon/SvgIcon';
import { format } from 'date-fns';
import './Feedback.scss';
import LocaleService from '@services/Locale.service';
import mova from 'mova';
import Avatar from '@components/display/Avatar/Avatar';

const t = mova.ns('components.Feedback');

const REVIEW_TRIM_LENGTH = 280;

function Feedback({ feedback, className, user, currentFeedback, openFeedbackForm }) {
  const [expanded, setExpanded] = useState(false);
  const { comment, kitchen, interior, service, mood, updatedAt, variables } = feedback;
  const name = variables?.author_name || user.firstName;
  const averageRating = (kitchen + interior + service + mood) / 4 || 0;
  const date = format(new Date(updatedAt), 'd MMMM yyyy', { locale: LocaleService.getLocaleObject() });

  return (
    <div className={`feedback ${className}`}>
      <div className='feedback__avatar'>
        <Avatar src={variables?.profile_photo_url} user={user} size={48} />
      </div>

      <div className='feedback__content'>
        <div className='feedback__top-right'>
          {
            currentFeedback &&
            <SvgIcon type='edit' onClick={openFeedbackForm} className='feedback__edit' fill={Colors.TEXT_SECONDARY} />
          }
          <div className='feedback__date'>
            {date}
          </div>
        </div>

        <div className='feedback__row feedback__flex-row'>
          {
            currentFeedback &&
            <span className='feedback__current'>{t('review')}</span>
          }
          <span className='feedback__name'>{name}</span>
          {/* <span className='feedback__title'>{title}</span> */}
          {/* <SvgIcon type='trophy' fill={Colors.COLOR_PRIMARY_STRONG} /> */}
          {/* <SvgIcon type='trophy' fill={Colors.COLOR_YELLOW} /> */}
          <StarRating rating={averageRating} className='feedback__rating' />
        </div>

        <div className='feedback__row feedback__text'>
          {expanded ? comment : comment?.substring(0, REVIEW_TRIM_LENGTH)}
          {comment?.length > REVIEW_TRIM_LENGTH && !expanded && '...'}
        </div>

        {comment?.length > REVIEW_TRIM_LENGTH && (
          <div className='feedback__row feedback__expand' onClick={() => setExpanded(!expanded)}>
            {expanded ? t('collapse') : t('expand')}
          </div>
        )}
      </div>
    </div>
  );
}

Feedback.propTypes = {
  feedback: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  className: PropTypes.string,
  currentFeedback: PropTypes.bool,
  openFeedbackForm: PropTypes.func
};

Feedback.defaultProps = {
  className: '',
  currentFeedback: false,
  openFeedbackForm: undefined
};

export default memo(Feedback);
