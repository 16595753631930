import React, { memo, useCallback } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import SvgIcon from '@components/display/SvgIcon/SvgIcon';
import './Calendar.scss';
import 'react-datepicker/dist/react-datepicker.css';
import LocaleService from '@services/Locale.service';
import { preventDefault } from '@utils/utils';
import CalendarCustomInput from '../CalendarCustomInput/CalendarCustomInput';
import { connect } from 'react-redux';
import mova from 'mova';
import { parseISO } from 'date-fns';

const t = mova.ns('components.Calendar');

function Calendar({ place, className, placeholder, onChange, value, today }) {
  const handleOnChange = useCallback((date) => {
    if (!place) {
      onChange(date);
      return;
    }

    const dayOfWeek = moment(date).format('E');
    const openHours = place.openHours.find(p => p.dayOfWeek === dayOfWeek);
    const dateWithOpenHours = moment(date)
      .isoWeekday(Math.ceil(openHours.dayOfWeek))
      .hours(openHours.openH)
      .minutes(openHours.openM)
      .toDate();
    onChange(dateWithOpenHours);
  }, [place, onChange]);

  return (
    <div className={`calendar__wrapper input-group-item__wrapper ${className}`}>
      <SvgIcon type='calendar' className='calendar__icon' />
      <span>{t('date')}:</span>
      <label onClick={preventDefault}>
        <DatePicker
          portalId='calendar-wrapper'
          selected={value}
          onChange={handleOnChange}
          dateFormat='d MMM yyy'
          locale={LocaleService.getLocaleObject()}
          placeholderText={placeholder}
          minDate={today}
          tabIndex={-1}
          customInput={<CalendarCustomInput className='calendar input-group-item' />}
          includeDates={place?.openDays?.days?.length ? place?.openDays?.days.map(date => parseISO(date)) : undefined}
        />
        <SvgIcon type='chevronDown' className='calendar__chevron' />
      </label>
    </div>
  );
}

Calendar.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Date),
  place: PropTypes.object,
  today: PropTypes.instanceOf(Date).isRequired,
};

Calendar.defaultProps = {
  className: '',
  placeholder: undefined,
  value: undefined,
  place: undefined,
};

const mapStateToProps = ({ app }) => ({
  today: new Date(app.time.data.current)
});

export default memo(connect(mapStateToProps)(Calendar));
