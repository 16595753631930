import React, { useEffect, useMemo, useState } from 'react';
import mova from 'mova';
import PropTypes from 'prop-types';
import './PlaceInfo.scss';
import Slider from '@components/display/Slider/Slider';
import SvgIcon from '@components/display/SvgIcon/SvgIcon';
import InfoTags from '@components/display/InfoTags';
import Loading from '@components/feedback/Loading/Loading';
import useMedia from '@utils/hooks/useMedia';
import { worksUntil } from '@utils/place/placeUtils';
import { get, isDefined, distinctBy } from '@utils/lo/lo';
import PlaceContacts from '@components/display/PlaceContacts/PlaceContacts';
import QuickReservation from '@containers/QuickReservation';
import memoize from 'memoize-one';
import Entity404 from '../Error/Entity404';
import DetailedRating from '@components/display/DetailedRating/DetailedRating';
import FeedbackForm from '@containers/FeedbackForm/FeedbackForm';
import { ReactComponent as VisaIcon } from '@theme/images/payment/visa.svg';
import { ReactComponent as MastercardIcon } from '@theme/images/payment/mastercard.svg';
import { ReactComponent as CashIcon } from '@theme/images/payment/cash.svg';
import { ReactComponent as GooglePayIcon } from '@theme/images/payment/g-pay.svg';
import { ReactComponent as ApplePayIcon } from '@theme/images/payment/apple-pay.svg';
import Feedback from '@components/display/Feedback/Feedback';
import Button from '@components/inputs/Button/Button';
import { CHANNEL_ID_QUERY_PARAM, DEFAULT_PAGE_SIZE, MOBILE_VIEW_WIDTH } from '@utils/constants';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import promoUtils from '@utils/place/promoUtils';
import seo from '@utils/seo';
import { Helmet } from 'react-helmet';
import { baseUrlPath, getUserLang } from '@utils/url/urlUtils';
import Switch from '@components/inputs/Switch/Switch';
import DishCard from '@components/display/DishCard/DishCard';
import ViewOrder from '../../components/display/ViewOrder/ViewOrder';
import { getCategoryDishes, getLikedDishes, getMenuCategories, getOrderAmount } from '@utils/menu/menu';
import api from '@api';
import Select from '../../components/inputs/Select/Select';
import SlickSlider from 'react-slick';
import moment from 'moment';

const t = mova.ns('pages.PlaceInfo');
const feedbackT = mova.ns('components.Feedback');

const FEEDBACK_PAGE_SIZE = DEFAULT_PAGE_SIZE;

const paymentIcons = {
  Cash: CashIcon,
  MasterCard: MastercardIcon,
  VISA: VisaIcon,
  GooglePay: GooglePayIcon,
  ApplePay: ApplePayIcon,
};

const placePayments = memoize((payments, tr) => (payments || []).map(p => {
  const PaymentIcon = paymentIcons[p.name] || CashIcon;

  return (
    <div className='payment-tag' key={p.name}>
      <PaymentIcon />
      <div className='payment-tag__text'>{tr[p.name] || p.name}</div>
    </div>
  );
}));

export default function PlaceInfo({
  match, place, getPlace, loading, toggleLike, likes, tagTranslations, user, reviews,
  reviewsLoading, getReviews, signUp, registerInPromo, showPaidServices, getCertificates, certificates,
  openCertificates, dateUpdated
}) {
  const [organizationPlaces, setOrganizationPlaces] = useState([]);
  const { placeUri } = match.params;
  const isAuthenticated = isDefined(user);
  const showDesktopQuickReserv = useMedia(['(min-width: 992px)'], [false], true);
  const showFullPageRating = useMedia(['(min-width: 1200px)'], [false], true);
  const showMobileView = useMedia([`(max-width: ${MOBILE_VIEW_WIDTH})`], [true], false);
  const location = useLocation();
  const history = useHistory();
  const urlParams = queryString.parse(location.search);
  const showMenu = urlParams.path === 'menu';
  const hasMenu = place?.dishes?.length > 0 && place.menuActive && place.clientMenuActive;
  const menuCategories = getMenuCategories();
  const activeCategory = place?.dishes?.flatMap(dish => dish.menus)
    .filter(distinctBy('id'))
    .find(c => c.slug === urlParams.category);
  const parentCategory = menuCategories.find(c => c.id === activeCategory?.parent?.id);
  const menuTotal = getOrderAmount();
  const likedDishes = getLikedDishes();

  const [feedbackFormOpen, setFeedbackFormOpen] = useState(false);
  const pageSize = FEEDBACK_PAGE_SIZE;

  const openFeedbackForm = () => setFeedbackFormOpen(true);
  const closeFeedbackForm = () => setFeedbackFormOpen(false);
  const showCertificates = place?.showCertificates && certificates.length > 0;

  const likeClicked = () => {
    toggleLike({ targetId: place.id }, likes, place);
  };

  const fetchMoreReviews = () => {
    getReviews(placeUri, reviews.page + 1, pageSize);
  };

  const toggleMenu = () => {
    const newParams = { ...urlParams, path: showMenu ? 'info' : 'menu' };
    history.push(`${location.pathname}?${queryString.stringify(newParams)}`);
  };

  useEffect(() => {
    getPlace(placeUri);
  }, [placeUri, getPlace]);

  useEffect(() => {
    getReviews(placeUri, 1, pageSize);
  }, [placeUri, getReviews, pageSize]);

  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    const { promo, [CHANNEL_ID_QUERY_PARAM]: channelId } = queryParams;

    if (place && promo === placeUri && channelId && place.translate?.channels?.[channelId]) {
      if (isAuthenticated) {
        registerInPromo(place, channelId);
      } else {
        promoUtils.activatePromo(place, channelId);
        signUp({ place, channelId });
      }

      delete queryParams.promo;
      history.push(`${location.pathname}?${queryString.stringify(queryParams)}`);
    }
  });

  useEffect(() => {
    if (showMenu && menuCategories.length > 0 && !urlParams.category) {
      const firstCategory = menuCategories[0].subcategories?.length
        ? menuCategories[0].subcategories[0].slug
        : menuCategories[0].slug;
      const newParams = { ...urlParams, category: firstCategory };
      history.push(`${location.pathname}?${queryString.stringify(newParams)}`);
    }
  }, [showMenu, place?.dishes]); // eslint-disable-line

  useEffect(() => {
    const organizationId = place?.organisations?.[0]?.id;
    if (organizationId) {
      api.places.getOrganizationPlaces(organizationId).then(res => setOrganizationPlaces(res.data));
    } else {
      setOrganizationPlaces([]);
    }
  }, [place]);

  useEffect(() => {
    if (place?.uri) {
      getCertificates(place?.uri);
    }
  }, [place?.uri, getCertificates]);

  useEffect(() => {
    if (place?.uri && place.openDays?.days?.length) {
      const today = moment().startOf('day');
      const nearestOpenDate = place.openDays?.days
        .map(date => moment(date)) // Convert each string to a moment object
        .find(date => date.isSameOrAfter(today));
      if (nearestOpenDate) {
        dateUpdated(new Date(nearestOpenDate), place.id);
      }
    }
  }, [place?.uri]); // eslint-disable-line

  const tags = useMemo(() => [...get(place, 'categories', []), ...get(place, 'mood', [])], [place]);

  if (loading) {
    return <Loading />;
  }

  if (!place) {
    return <Entity404 entity={t('place')} />;
  }

  const language = getUserLang();

  const currentFeedback = place.currentReview && (
    <Feedback
      key={place.currentReview.id}
      feedback={place.currentReview}
      user={user}
      className='place-info__feedback'
      currentFeedback
      openFeedbackForm={openFeedbackForm}
    />
  );

  const feedbackCards = reviews.rows.filter(review => review.userId !== get(user, 'id')).map(item => (
    <Feedback
      key={item.id}
      feedback={item}
      user={item.user}
      className='place-info__feedback'
    />
  ));

  const containsUserFeedback = isDefined(place.currentReview);
  const likedPlace = likes.filter(item => item.id === place.id);
  const isLiked = likedPlace.length === 0 ? place.isLiked : likedPlace[0].isLiked;

  const goToOtherPlace = (other) => {
    if (other.id !== place.id) {
      history.push(`${baseUrlPath()}/places/${other.uri}`);
    }
  };

  const placeNameSelect = (
    <Select
      defaultValue={place}
      labelPath='name'
      options={organizationPlaces}
      onSelect={goToOtherPlace}
      className='app-header__city-select'
    />
  );

  const placeNameSection = (
    <div className='tags__header' title={place.name}>
      {organizationPlaces?.length > 0 ? placeNameSelect : place.name}
      {
        isAuthenticated && (
          <SvgIcon
            className='tags__like'
            type='heart'
            onClick={likeClicked}
            filled={isLiked}
          />
        )
      }
    </div>
  );

  const goToCategory = (category) => {
    const newCategory = category.subcategories?.length ? category.subcategories[0].slug : category.slug;
    const newParams = { ...urlParams, category: newCategory };
    history.push(`${location.pathname}?${queryString.stringify(newParams)}`);
  };

  const categoryButtons = (
    <SlickSlider
      variableWidth
      arrows
      swipeToSlide
      infinite={false}
      centerMode={false}
      slidesToShow={Math.min(3, menuCategories.length)}
      slidesToScroll={Math.min(2, menuCategories.length)}
      className='place-info__menu-categories'
      responsive={[
        {
          breakpoint: 600,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]}
    >
      {likedDishes.length > 0 && (
        <Button
          inputClass='place-info__menu-category'
          onClick={() => goToCategory({ slug: 'liked' })}
          type={urlParams.category === 'liked' ? 'default' : 'flat'}
          size='large'
        >
          <SvgIcon type='heart' filled iconProps={{ size: 20 }} /> {t('likedCategory')}
        </Button>
      )}
      {menuCategories.map(c => (
        <Button
          inputClass='place-info__menu-category'
          key={c.id}
          onClick={() => goToCategory(c)}
          type={activeCategory?.slug === c.slug || activeCategory?.parent?.slug === c.slug ? 'default' : 'flat'}
        >
          {c.name}
        </Button>
      ))}
    </SlickSlider>
  );

  const subcategoryButtons = (
    <SlickSlider
      variableWidth
      arrows
      swipeToSlide
      infinite={false}
      slidesToShow={Math.min(parentCategory?.subcategories?.length, 3)}
      slidesToScroll={Math.min(parentCategory?.subcategories?.length, 3)}
      className='place-info__menu-categories place-info__menu-categories--subcategories'
      responsive={[
        {
          breakpoint: 600,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]}
    >
      {parentCategory?.subcategories?.map(c => (
        <Button
          inputClass='place-info__menu-category place-info__menu-category--subcategory'
          key={c.id}
          onClick={() => goToCategory(c)}
          type={activeCategory?.slug === c.slug || activeCategory?.parent?.slug === c.slug ? 'default' : 'flat'}
        >
          {c.name}
        </Button>
      ))}
    </SlickSlider>
  );

  const dishes = urlParams.category === 'liked' ? likedDishes : getCategoryDishes(activeCategory);
  const dishCards = dishes
    .sort((d1, d2) => d2.variables?.top - d1.variables?.top)
    .map(dish => <DishCard dish={dish} key={dish.id} />);

  return (
    <div className='place-info'>
      <Helmet>
        <title>{seo.placeTitle[language] + place.name}</title>
      </Helmet>
      <div style={{ position: 'relative' }}>
        <Slider place={place} />
        {hasMenu && (
          <div className='place-info__switch-container'>
            <Switch
              left={t('info')}
              right={t('menu')}
              className='place-info__menu-switch'
              leftActive={!showMenu}
              rightActive={showMenu}
              onClick={toggleMenu}
            />
          </div>
        )}
      </div>
      <div className='place-info__content'>
        {!showMenu && (
          <div className='place-info__left'>
            <div className='place-info__left-top'>
              <div className='place-info__tags-top tags'>
                {placeNameSection}
                <div className='tags__item tags__top-item'>
                  <SvgIcon className='tags__icon' type='time' />
                  <div className={`tags__highlight ${!place.isOpen && 'tags__highlight--danger'} tags__margin-bottom `}>
                    {place.isOpen ? t('status.open') : t('status.closed')}
                  </div>
                  <div className='tags__flex-center tags__margin-bottom'>
                    <span>{place.isOpen ? t('closing') : t('opening')}</span>&nbsp;
                    <span>{worksUntil(place)}</span>
                  </div>
                  <div className='tags__flex-center tags__margin-bottom'>
                    <SvgIcon type='money' />&nbsp;
                    <span>{t('averageBill')}</span>&nbsp;
                    <span className='tags__highlight'>{place.averageBill}</span>
                  </div>
                </div>

                {showDesktopQuickReserv && <QuickReservation className='place-info__quick-reservation' />}

                <div className='tags__item'>
                  <SvgIcon className='tags__icon' type='forkKnife' />
                  <div className='tags__item-heading'>{t('cuisine')}</div>
                  <div className='tags__item-content'>
                    <InfoTags tags={place.cusines} />
                  </div>
                </div>

                <div className='tags__item'>
                  <SvgIcon className='tags__icon' type='tags' />
                  <div className='tags__item-heading'>{t('additional')}</div>
                  <div className='tags__item-content'>
                    <InfoTags tags={tags} />
                  </div>
                </div>

                {place?.paidService?.active && place?.paidService?.images?.length > 0 && (
                  <div className='tags__item'>
                    <SvgIcon className='tags__icon' type='additional' />
                    <div className='tags__item-heading'>{t('paidServices')}</div>
                    <div className='tags__item-content'>
                      <Button type='flat' onClick={() => showPaidServices(place.paidService)}>
                        {place?.paidService.label || t('viewPaidServices')}
                      </Button>
                    </div>
                  </div>
                )}

                {showCertificates && (
                  <div className='tags__item'>
                    <SvgIcon className='tags__icon' type='certificates' />
                    <div className='tags__item-heading'>{t('certificates')}</div>
                    <div className='tags__item-content'>
                      <Button type='flat' onClick={() => openCertificates(certificates)}>
                        {t('view')}
                      </Button>
                    </div>
                  </div>
                )}

                <div className='tags__item'>
                  <SvgIcon className='tags__icon' type='creditCard' />
                  <div className='tags__item-heading'>{t('payment')}</div>
                  <div className='tags__item-content tags__payments'>
                    {placePayments(place.payments, tagTranslations)}
                  </div>
                </div>
              </div>
              {
                !showFullPageRating && (
                  <DetailedRating
                    className='place-info__detailed-rating'
                    place={place}
                    openFeedbackForm={openFeedbackForm}
                  />
                )
              }
            </div>

            <div className='tags__item'>
              <SvgIcon className='tags__icon' type='description' />
              <div className='tags__item-heading'>{t('description')}</div>
              <div className='tags__item-content'>{place.description}</div>
            </div>

            {
              showFullPageRating && (
                <DetailedRating
                  className='place-info__detailed-rating'
                  place={place}
                  openFeedbackForm={openFeedbackForm}
                />
              )
            }
            <div className='tags__item'>
              <SvgIcon className='tags__icon' type='comment' />
              <div className='tags__item-heading tags__comment-heading'>{t('comments')}
                <div className='tags__quantity'>{reviews.count}</div>
                {containsUserFeedback && <div className='tags__item--already-left'>{feedbackT('alreadyLeft')}</div>}
              </div>
              {
                place.isVisitor && !containsUserFeedback && !showMobileView &&
                <SvgIcon className='form-icon' type='edit' fill='#fff' onClick={openFeedbackForm} />
              }
              {
                place.isVisitor && !containsUserFeedback && showMobileView && (
                  <Button size='medium' onClick={openFeedbackForm} inputClass='leave-feedback-button'>
                    <SvgIcon className='form-icon--mobile' type='edit' fill='#fff' />
                    {t('leaveFeedback')}
                  </Button>
                )
              }
              <div className='tags__item-content'>
                {currentFeedback}
                {
                  feedbackFormOpen && (
                    <FeedbackForm
                      className='place-info__feedback-form'
                      user={user}
                      place={place}
                      closeForm={closeFeedbackForm}
                      review={place.currentReview || {}}
                    />
                  )
                }
                {feedbackCards}
                {reviewsLoading && <Loading inline />}
                {
                  !reviewsLoading && feedbackCards.length === 0 && !place.currentReview &&
                  <div>{feedbackT('empty')}</div>
                }
              </div>
            </div>
            {
              !reviewsLoading && reviews.page < reviews.totalPages && (
                <Button
                  fullWidth={showMobileView}
                  inputClass='place-info__more-reviews'
                  onClick={fetchMoreReviews}
                >
                  {t('moreReviews', { pageSize })}
                </Button>
              )
            }
          </div>
        )}
        {showMenu && (
          <div className='place-info__left'>
            {placeNameSection}
            {categoryButtons}
            {activeCategory?.parent && subcategoryButtons}
            <div className='place-info__menu-dishes'>
              {dishCards}
            </div>
          </div>
        )}
        <div className='place-info__right'>
          {menuTotal > 0 && <ViewOrder className='place-info__view-order' />}
          {!showDesktopQuickReserv && <QuickReservation className='place-info__quick-reservation' />}
          <PlaceContacts place={place} className='place-info__contacts' />
        </div>
      </div>
    </div>
  );
}

PlaceInfo.propTypes = {
  match: PropTypes.object.isRequired,
  place: PropTypes.object,
  likes: PropTypes.array,
  reviews: PropTypes.object,
  getPlace: PropTypes.func.isRequired,
  toggleLike: PropTypes.func.isRequired,
  getReviews: PropTypes.func.isRequired,
  signUp: PropTypes.func.isRequired,
  registerInPromo: PropTypes.func.isRequired,
  showPaidServices: PropTypes.func.isRequired,
  getCertificates: PropTypes.func.isRequired,
  openCertificates: PropTypes.func.isRequired,
  dateUpdated: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  reviewsLoading: PropTypes.bool.isRequired,
  tagTranslations: PropTypes.object.isRequired,
  user: PropTypes.object,
  certificates: PropTypes.array.isRequired,
};

PlaceInfo.defaultProps = {
  place: null,
  likes: [],
  reviews: {},
  user: undefined
};
