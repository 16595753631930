export default {
  name: 'es',
  base: {
    cancel: 'Cancelar',
    signIn: 'Iniciar sesión',
    signUp: 'Registrarse',
    signOut: 'Cerrar sesión',
    submitReservationForm: 'Reservado para <=seats>',
    submitFilterForm: 'Buscar...',
    save: 'Guardar cambios',
    anycafe: 'RESERVABLE',
    book: 'Reservar',
    selectTime: 'Seleccione hora',
    or: 'O',
    createAccount: 'Crear cuenta',
    back: 'Regresar',
    ok: 'Ok',
    total: 'Total',
  },
  cities: {
    odessa: 'Odesa',
    kyiv: 'Kiev',
    ternopil: 'Ternópil',
    dnipro: 'Dnipró',
    lviv: 'Lviv',
    kharkiv: 'Járkov',
    uzhhorod: 'Úzhgorod',
    bilatserkv: 'Bila Tserkva',
    chernihiv: 'Cherníhiv',
    podilsk: 'Podilsk',
    mukacheve: 'Mukachevo',
    helsinki: 'Helsinki',
  },
  currencies: {
    uah: 'UAH',
  },
  types: {
    likes: {
      add: 'Has añadido <=place> a tus establecimientos favoritos',
      delete: 'Has eliminado <=place> de tus establecimientos favoritos',
      addDish: 'Plato añadido a la lista de favoritos',
      deleteDish: 'Plato eliminado de la lista de favoritos',
    },
    user: {
      phone: 'Teléfono',
      phoneLower: 'teléfono',
      email: 'Correo electrónico',
      emailLower: 'correo electrónico',
      password: 'Contraseña',
      newPassword: 'Nueva contraseña',
      repeatPassword: 'Repetir contraseña',
      name: 'Nombre',
      surname: 'Apellido',
      rememberMe: 'Recuérdame',
    },
    reservation: {
      '@extends': 'types.user',
      button: 'Reservar',
      comment: '¿Tiene algún deseo?',
      date: 'Fecha/Hora',
      status: {
        REQUESTED: 'En proceso',
        ACCEPTED: 'Confirmado',
        FINISHED: 'Terminado',
        CANCELED: 'Cancelado',
        SKIPPED: 'Omitido',
        REJECTED: 'Rechazado',
      },
    },
    filters: {
      city: 'Seleccione ciudad',
    },
    feedback: {
      thankYou: 'Gracias por su comentario',
    },
    join: {
      thankYou: 'Gracias por su solicitud. Nuestro administrador se pondrá en contacto con usted próximamente',
    },
  },

  errors: {
    '@root': 'Error desconocido',
    '@any': '<.>',
    error: 'Error',
    server: 'Ha ocurrido un error desconocido en el servidor',
    validation: {
      '@root': 'Formato incorrecto',
      '@any': '<.>',
      required: 'Campo obligatorio',
      email: 'Ingrese un <types.user.emailLower> correcto',
      phone: 'Ingrese un <types.user.phoneLower> correcto',
      minLength: {
        1: 'Mínimo <=symbols> símbolo',
        2: 'Mínimo <=symbols> símbolos',
        5: 'Mínimo <=symbols> símbolos',
      },
      maxLength: {
        1: 'Máximo <=symbols> símbolo',
        2: 'Máximo <=symbols> símbolos',
        5: 'Máximo <=symbols> símbolos',
      },
      minNumber: 'Valor mínimo <=min>',
      maxNumber: 'Valor máximo <=max>',
      repeatPassword: 'Las contraseñas no coinciden',
    },
    fields: {
      email: 'correo electrónico',
      phone: 'teléfono',
      notUniq: 'Ya existe un usuario con ese <=field> en el sistema',
    },
    common: {
      unauthorized: 'Esta acción solo puede ser realizada por un usuario autorizado',
      wrongAuth: 'Teléfono o contraseña incorrectos. Verifique la exactitud de los datos introducidos',
      notFound: 'Objeto de búsqueda no encontrado',
      oldPasswordIsInvalid: 'La contraseña actual es incorrecta. Intente de nuevo',
      justVisitorCanLeaveAComment: 'Solo un visitante confirmado puede dejar un comentario',
      userWithSpecifiedEmailNotFound: 'No se encontró un usuario con la dirección especificada',
    },
    join: 'Ha ocurrido un error desconocido en el servidor. Por favor, envíe un mensaje a nuestro administrador al número indicado al final de la página',
  },

  containers: {
    SearchBar: {
      placeholder: 'Nombre, cocina, tipo, dirección',
      search: 'Buscar',
      useAi: 'Usa magia de AI',
    },
    Header: {
      signIn: '<base.signIn>',
      signUp: '<base.signUp>',
      signOut: '<base.signOut>',
      placeOwner: 'Para propietarios de establecimientos',
      join: 'Conectar establecimiento',
    },

    SortResults: {
      sortBy: 'Ordenar por:',
      sort: {
        alphabet: 'Alfabeto',
        rating: 'Calificación',
        nearest: 'Más cercanos',
        expensive: 'Más caros',
        cheap: 'Más baratos',
        free: 'Más plazas libres',
      },
    },

    UserFeedbacks: {
      empty: 'Aún no tienes comentarios',
      moreReviews: 'Mostrar más <=pageSize> comentarios',
    },

    MailingPreferences: {
      notifications: 'Enviarme notificaciones por correo',
      discounts: 'Enviarme noticias de descuentos por correo',
      selections: 'Enviarme selecciones de restaurantes por correo',
      save: '<base.save>',
    },

    UserPreferences: {
      city: 'Ciudad principal',
      cityPlaceholder: 'Su ciudad principal de residencia',
      allergy: 'Intolerancias / Alergias',
      allergyPlaceholder: 'Indique cualquier intolerancia o alergia',
      favoriteCuisine: 'Cocinas favoritas',
      favoriteCuisinePlaceholder: 'Cuéntenos qué cocinas le gustan',
      favoriteDishes: 'Platos favoritos',
      favoriteDishesPlaceholder: 'Cuéntenos sobre sus platos favoritos',
      unliked: 'Ingredientes / platos que no le gustan',
      unlikedPlaceholder: 'Cuéntenos lo que no le gusta',
      save: '<base.save>',
    },

    UserProfile: {
      photo: {
        delete: 'Eliminar foto',
        new: 'Nueva foto',
      },
      info: 'Info',
      stats: {
        cities: {
          1: 'ciudad',
          2: 'ciudades',
          5: 'ciudades',
        },
        visits: {
          1: 'visita',
          2: 'visitas',
          5: 'visitas',
        },
        rates: {
          1: 'calificación',
          2: 'calificaciones',
          5: 'calificaciones',
        },
        feedbacks: {
          1: 'comentario',
          2: 'comentarios',
          5: 'comentarios',
        },
      },
      achievement: {
        rates: 'calificaciones en <base.anycafe>',
        bookings: 'reservas en <base.anycafe>',
      },
      data: {
        name: '<types.user.name>',
        namePlaceholder: 'Introduzca su nombre',
        surname: '<types.user.surname>',
        surnamePlaceholder: 'Introduzca su apellido',
        phone: '<types.user.phone>',
        email: '<types.user.email>',
        emailPlaceholder: 'Introduzca su <types.user.emailLower>',
        password: '<types.user.password>',
        passwordPlaceholder: 'Introduzca su contraseña',
        confirmPassword: 'Confirmación de contraseña',
        confirmPasswordPlaceholder: 'Confirme su contraseña',
        newPassword: '<types.user.newPassword>',
        newPasswordPlaceholder: '<types.user.newPassword>',
      },
      social: {
        link: 'Conectar redes sociales',
        linked: 'conectado',
        notLinked: 'no conectado',
        linkButton: 'Conectar',
        unlinkButton: 'Desconectar',
      },
      save: '<base.save>',
    },

    QuickReservation: {
      book: '<base.book>',
      selectTime: '<base.selectTime>',
      noOptions: 'Desafortunadamente, no hay mesas disponibles en el tiempo seleccionado',
      nextDay: 'Verificar el día siguiente',
    },

    TopUserMenu: {
      notifications: 'Notificaciones',
      currentReserve: 'Reservas actuales',
      history: 'Historial',
      favorites: 'Favoritos',
      profile: 'Perfil',
      logout: '<base.signOut>',
    },

    FinishRegistrationModal: {
      lastStep: 'Solo queda un paso para completar el registro :)',
      password: '<types.user.password>',
      repeatPassword: '<types.user.repeatPassword>',
      rememberMe: '<types.user.rememberMe>',
      createAccount: '<base.createAccount>',
    },

    AskPhoneModal: {
      lastStep: 'Solo queda un paso para completar su reserva :)',
      text:
        'Proporcione su teléfono para que el administrador pueda llamarle si es necesario',
      continue: 'Continuar',
    },

    LoginRegisterModal: {
      signInTitle: 'Por favor, inicie sesión en <base.anycafe>',
      signUpTitle: 'Bienvenido a <base.anycafe>',
      or: '<base.or>',
      name: '<types.user.name>',
      surname: '<types.user.surname>',
      email: '<types.user.email>',
      password: '<types.user.password>',
      repeatPassword: '<types.user.repeatPassword>',
      rememberMe: '<types.user.rememberMe>',
      forgotPassword: '¿Olvidó la contraseña?',
      signIn: '<base.signIn>',
      signUp: '<base.createAccount>',
      newUserQuestion: '¿Es nuevo aquí?',
      haveAccountQuestion: '¿Ya está registrado?',
      signUpLink: 'Registrarse',
      signInLink: 'Iniciar sesión',
    },

    PromoSuccessModal: {
      congrats: '¡Felicidades!',
      defaultMessage: 'Usted está participando en la promoción',
    },

    CancelReservationModal: {
      title: 'Cancelar reserva',
      why: 'Por favor, indique por qué desea cancelar la reserva',
      changeOfPlans: 'Cambio de planes',
      mistake: 'La reserva se creó por error',
      late: 'No llegaré a tiempo',
      changeTime: 'Quiero elegir otro horario',
      other: 'Otro',
      cancel: 'Cancelar',
    },

    AllowGeolocationModal: {
      title: 'Acceso a geolocalización',
      text:
        'Para poder mostrarle los resultados de búsqueda más relevantes y la distancia al restaurante, por favor, permita el acceso a su geolocalización',
      ok: '<base.ok>',
    },

    FeedbackForm: {
      whatAbout: '¿Cómo le pareció?',
      comment: 'Su comentario:',
      commentPlaceholder: '¿Qué le pareció el lugar? (opcional)',
      cancel: '<base.cancel>',
      send: 'Enviar',
    },

    ForgotPasswordModal: {
      title: 'Recuperar contraseña',
      text: 'Introduzca su <types.user.emailLower>',
      textSms: 'Introduzca su <types.user.phone>',
      email: '<types.user.email>',
      send: 'Enviar',
      sendAgain: 'Enviar de nuevo',
      back: '<base.back>',
      or: '<base.or>',
      codeReceived: 'He recibido el SMS con el código',
    },

    NewPasswordModal: {
      title: 'Cambio de contraseña',
      text: 'Introduzca el código del SMS',
      back: '<base.back>',
      code: 'Código del SMS',
      send: 'Enviar',
      newPassword: '<types.user.newPassword>',
      repeatPassword: '<types.user.repeatPassword>',
      successPasswordChange: 'La contraseña ha sido cambiada con éxito',
    },

    ChangeCityPopup: {
      yourCity: 'Su ciudad',
      yes: 'Sí',
      no: 'No, dejar',
      cities: {
        '@extends': 'cities',
      },
    },

    ReservationSuccessModal: {
      title: '¡Felicidades!',
      p1: '¡Mesa reservada! Recibirá un mensaje SMS después de la confirmación del administrador.',
      p1noSms: 'Amigo, ¡la mesa ha sido reservada con éxito! Si tenemos alguna pregunta, te llamaremos.',
      p2: 'Regístrese y obtenga más ventajas de Reservable.',
      register: 'Registrarse',
    },

    CartModal: {
      title: 'Su pedido',
      total: '<base.total>',
      uah: '<currencies.uah>',
      empty: 'Aún no ha seleccionado ningún plato',
      clear: 'Limpiar pedido',
    },

    Footer: {
      privacyPolicy: '<pages.Policy.privacy>',
      cookiePolicy: '<pages.Policy.cookies>',
      paymentsPolicy: '<pages.Policy.payments>',
      nearMe: 'Restaurantes cerca de mí',
      openNow: 'Restaurantes abiertos ahora',
      topRated: 'Los más valorados',
      areYouRestaurant: '¿Eres restaurante?',
      pricing: 'Planes y precios',
      requestDemo: 'Solicitar una demostración',
    },

    SelectSpotModal: {
      title: 'Elegir un lugar',
      book: '<base.book>',
      skip: 'Salta',
      uah: '<currencies.uah>',
      selectDepositType: 'Elige un tipo',
    },
  },

  components: {
    Feedback: {
      review: 'Su comentario',
      alreadyLeft: 'Ya ha dejado un comentario',
      empty: 'Todavía no hay comentarios sobre este lugar',
      expand: 'Expandir',
      collapse: 'Contraer',
    },
    Success: {
      successMsg: '¡Éxito!',
    },
    InfoTags: {
      more: 'Más',
    },
    TimeTags: {
      more: 'Más',
    },
    CookieFooter: {
      message:
        'Usamos cookies para asegurar el mejor funcionamiento de nuestro servicio. Al usar este sitio, acepta el uso de cookies.',
      learnMore: 'Aprender más',
      okText: 'Ok',
    },
    ViewSelector: {
      map: 'Mapa',
      tiles: 'Cuadrícula',
    },
    Distance: {
      m: 'm',
      km: 'km',
    },
    PlaceCard: {
      cancelReservation: 'Cancelar reserva',
      reservationsToday: {
        1: 'persona ha reservado hoy',
        2: 'personas han reservado hoy',
        5: 'personas han reservado hoy',
      },
    },
    QueryResult: {
      results: {
        1: 'resultado encontrado',
        2: 'resultados encontrados',
        5: 'resultados encontrados',
      },
    },
    SocialLoginButton: {
      text: 'Iniciar sesión con <=provider>',
      google: 'Google',
      facebook: 'Facebook',
    },
    PlaceContacts: {
      address: 'Dirección:',
      contacts: 'Contactos:',
      webSite: 'Sitio web:',
    },
    DetailedRating: {
      heading: 'Calificación del establecimiento',
      kitchen: 'Cocina',
      interior: 'Interior',
      service: 'Servicio',
      atmosphere: 'Atmósfera',
      noFeedbacks: '<components.Feedback.empty>',
      leaveFeedback: '<pages.PlaceInfo.leaveFeedback>',
      beTheFirst: '. ¡Sea el primero!',
    },
    PersonsSelector: {
      placeholder: 'Persona',
      suffix: 'pers.',
      guests: 'Invitados',
    },
    ReservationDetails: {
      persons: 'pers.',
    },
    DishCard: {
      uah: '<currencies.uah>',
      like: 'Me gusta',
    },
    ViewOrder: {
      view: 'Ver',
      uah: '<currencies.uah>',
    },
    Calendar: {
      date: 'Fecha',
    },
    TimeSelector: {
      time: 'Tiempo',
    },
    CertificatesModal: {
      title: 'Certificados',
      buy: 'Comprar',
      uah: '<currencies.uah>',
      subscription: 'Suscripción',
      expand: 'Expandir',
      days: {
        1: 'día',
        2: 'días',
        5: 'días',
      },
      visits: {
        1: 'visita',
        2: 'visitas',
        5: 'visitas',
      },
    },
  },

  pages: {
    Search: {
      noResults: 'No se encontraron restaurantes para su búsqueda',
      topLabel: 'Una búsqueda, muchas posibilidades',
    },

    Favorites: {
      empty: 'Aún no tiene lugares favoritos',
      favorites: 'Ha añadido',
      places: {
        1: 'establecimiento a la lista de favoritos',
        2: 'establecimientos a la lista de favoritos',
        5: 'establecimientos a la lista de favoritos',
      },
    },

    Notifications: {
      youHave: 'Tiene',
      notifications: {
        1: 'nuevo mensaje',
        2: 'nuevos mensajes',
        5: 'nuevos mensajes',
      },
      new: 'Nuevos',
      read: 'Leídos',
      reservation: {
        reserv: 'Su reserva en',
        atDate: 'para',
        for: 'para',
        persons: {
          1: 'persona',
          2: 'personas',
          5: 'personas',
        },
        confirmed: 'ha sido confirmada',
        rejected: 'ha sido rechazada',
        awaiting: 'está esperando confirmación',
        cancelled: 'ha sido cancelada',
        finished: 'ha sido completada con éxito. ¡Esperamos verle de nuevo!',
        skipped: 'ha sido marcada como omitida',
        message: '. Mensaje del administrador: "<=message>"',
        didYouLike: '¿Le gustó el restaurante?',
        leaveFeedback: '¡Deje un comentario!',
      },
    },

    DemoStub: {
      message: '<base.anycafe> en beta-test',
      open: 'Apertura oficial',
      openDate: '1 de febrero de 2020',
      countdown: {
        days: 'días',
        hours: 'horas',
        minutes: 'minutos',
        seconds: 'segundos',
      },
    },

    Error: {
      error404: 'Lo sentimos, la página que busca no se encuentra',
      error500: {
        message: '¡Ha roto el Internet!',
        details:
          'Es broma, parece que tenemos un pequeño problema en el servidor, intente su solicitud de nuevo en unos minutos',
      },
      entity404: 'Lo sentimos, <=entity> no encontrado',
      reservationNotDraft: 'Esta reserva ya ha sido procesada',
      goToReservations:
        'Ir a la página "<containers.TopUserMenu.currentReserve>"',
      goHome: 'Volver a la página principal',
    },

    Profile: {
      profile: 'Mis datos',
      preferences: 'Preferencias',
      feedback: 'Mis comentarios',
      mailing: 'Boletines informativos',
      update: 'Datos actualizados con éxito',
    },

    FinishReservation: {
      almost: '¡Casi listo!',
      timer: {
        prefix1: 'Reservamos',
        prefix2: 'un lugar para usted durante',
        suffix: 'minutos',
      },
      or: '<base.or>',
      name: '<types.user.name>',
      surname: '<types.user.surname>',
      email: '<types.user.email>',
      selectHall: 'Puede elegir la sala:',
      depositOptions: 'Opciones adicionales:',
      depositsRequired: 'Debes seleccionar al menos una opción adicional para crear una reserva',
      certificate: 'Certificado',
      certificateId: 'ID del certificado',
      certificateNotFound: 'No se encontró el certificado con este número',
      uah: '<currencies.uah>',
      total: '<base.total>',
      comment: 'Su comentario',
      subscribe: 'Suscribirse al boletín',
      notifications: 'Recibir recordatorios',
      buttonName: '<types.reservation.button>',
      reservation: 'reserva',
      back: 'Volver al restaurante',
      submit: '<types.reservation.button>',
      selectedHall: 'Sala seleccionada',
      selectedSpots: 'Asientos seleccionados',
      deposits: 'Depósitos',
    },

    Payment: {
      reservation: 'reserva',
      title: 'Pago de deposito',
      uah: '<currencies.uah>',
      success: '¡El pago se realizó con exito!',
      back: 'Volver al restaurante',
      linkExpired: 'Su enlace de pago ha caducado.',
      tryAgain: 'Intenta reservar de nuevo',
      tryAgainButton: 'Reservar de nuevo',
      orderNumber: 'Número de pedido',
      paymentTime: 'Hora de pago',
      amount: 'Total de la orden',
      waitFormLoad: 'Espere a que se cargue el formulario de pago sin recargar la página',
      payWithPortmone: 'Pagar con Portmone.com',
    },

    PlaceInfo: {
      cuisine: 'Cocina',
      additional: 'Adicional',
      payment: 'Formas de pago',
      certificates: 'Certificados y suscripciones',
      view: 'Ver',
      info: 'Información',
      menu: 'Menú',
      likedCategory: 'Favoritos',
      description: 'Descripción',
      comments: 'Comentarios',
      status: {
        open: 'Abierto',
        closed: 'Cerrado',
      },
      closing: 'Cierre:',
      opening: 'Apertura:',
      averageBill: 'Cuenta promedio',
      place: 'restaurante',
      leaveFeedback: 'Dejar un comentario',
      moreReviews: 'Mostrar más <=pageSize> comentarios',
      paidServices: 'Servicios adicionales',
      viewPaidServices: 'Ver servicios',
    },

    PlaceFeedback: {
      leaveFeedback: 'Deje un comentario para',
      place: 'restaurante',
      user: 'usuario',
    },

    MyReservations: {
      summary: {
        booked: {
          1: 'Reservado',
          2: 'Reservados',
          5: 'Reservados',
        },
        tables: {
          1: 'lugar',
          2: 'lugares',
          5: 'lugares',
        },
        pending: {
          1: ', esperando confirmación',
          2: ', esperando confirmación',
          5: ', esperando confirmación',
        },
      },
      inProcess: 'Esperando confirmación',
      confirmed: 'Confirmado',
      empty: 'No se encontraron reservas activas',
    },

    ReservationHistory: {
      summary: {
        visits: 'Ha visitado',
        reservations: {
          1: 'restaurante',
          2: 'restaurantes',
          5: 'restaurantes',
        },
      },
      empty: 'No se encontraron reservas anteriores',
    },

    Policy: {
      privacy: 'Política de privacidad',
      privacyGeneric: 'Política de privacidad',
      cookies: 'Política de cookies',
      cookiesGeneric: 'Política de cookies',
      payments: 'Política de pago',
      meetings: 'Acuerdo de Oferta de Reuniónes',
    },

    GptPlaceSearch: {
      back: 'Volver a la página principal',
      title: 'Usamos la magia de IA',
      firstMessage: 'Hola, soy el chat de Reservble AI. Escribe un plato, cocina, bebida o la experiencia que quieres tener',
      placeholderMessages: 'Pregúntame: "¿Dónde comer bistec?"',
    },

    BuyCertificate: {
      title: 'Elegir un certificado',
      checkPhone: 'Este campo es obligatorio. Comprueba atentamente el número de teléfono indicado, te enviamos certificados por SMS',
      payWithPortmone: 'Pagar con Portmone.com',
      fields: { '@extends': 'types.user' },
      errors: { '@extends': 'errors.validation' },
    }
  },

  actions: {
    user: {
      forgotPasswordSent:
        'Se ha enviado con éxito un enlace para restablecer la contraseña. Verifique su <types.user.emailLower>',
      forgotSmsSent: 'Le hemos enviado un mensaje SMS con el código',
      passwordChanged: 'La contraseña ha sido cambiada con éxito',
    },
  },

  legal: {
    heading: '<pages.Policy.privacy>',
    confirmation: {
      '@extends': 'pages.Policy',
      confirm1: 'Al hacer clic en el botón <=buttonName>, acepta nuestra',
      confirm2: ', así como nuestra',
      confirm3: ', incluyendo',
    },
  },
};
