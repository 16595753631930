import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PlaceInfo from './PlaceInfo';
import { getPlace, getReviews, getCertificates, registerInPromo } from '@actions/place';
import { toggleLike } from '@actions/user';
import { get } from '@utils/lo/lo';
import { openModal } from '@actions/modal';
import { REGISTRATION_MODAL, PAID_SERVICES_MODAL, CERTIFICATES_MODAL } from '@constants/modal';
import { saveQuickDate } from "@actions/reservation";

const mapStateToProps = ({ place, app, user }) => ({
  place: place.details.data,
  orders: place.menu, // needed to re-render component in case of order updates
  loading: place.details.isLoading || (!place.details.data && !place.details.error),
  likes: user.likes.data,
  tagTranslations: app.tags.data,
  user: get(user, 'details.data.user'),
  reviews: place.reviews,
  reviewsLoading: place.reviews.isLoading,
  certificates: (place.certificates.data || []).filter(c => c.active),
});

const mapDispatchToProps = {
  getPlace,
  toggleLike,
  getReviews,
  signUp: (meta) => openModal(REGISTRATION_MODAL, meta),
  showPaidServices: (meta) => openModal(PAID_SERVICES_MODAL, meta),
  openCertificates: (meta) => openModal(CERTIFICATES_MODAL, meta),
  registerInPromo,
  getCertificates,
  dateUpdated: saveQuickDate,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PlaceInfo));
