module.exports = {
  ODESSA: {
    id: 1,
    name: 'odessa',
    enabled: true,
    center: {
      lat: 46.487351643019,
      lng: 30.739215365253028
    }
  },
  KYIV: {
    id: 2,
    name: 'kyiv',
    enabled: true,
    center: {
      lat: 50.450001,
      lng: 30.523333
    }
  },
  DNIPRO: {
    id: 3,
    name: 'dnipro',
    enabled: false,
    center: {
      lat: 48.462723,
      lng: 35.049403
    }
  },
  TERNOPIL: {
    name: 'ternopil',
    enabled: false,
    center: {
      lat: 49.553516,
      lng: 25.594767
    }
  },
  KHARKIV: {
    id: 5,
    name: 'kharkiv',
    enabled: false,
    center: {
      lat: 50.0000,
      lng: 36.2292
    }
  },
  DONETSK: {
    name: 'donetsk',
    enabled: false,
    center: {
      lat: 48.0089,
      lng: 37.8042
    }
  },
  ZAPORIZHIA: {
    name: 'zaporizhia',
    enabled: false,
    center: {
      lat: 47.8378,
      lng: 35.1383
    }
  },
  LVIV: {
    id: 6,
    name: 'lviv',
    enabled: true,
    center: {
      lat: 49.8419,
      lng: 24.0315
    },
    zoom: 16,
  },
  IRPIN: {
    id: 7,
    name: 'irpin',
    enabled: false,
    center: {
      lat: 50.532245,
      lng: 30.245135
    },
    zoom: 16,
  },
  MYKOLAIV: {
    name: 'mykolaiv',
    enabled: false,
    center: {
      lat: 46.9667,
      lng: 32.0000
    }
  },
  SEVASTOPOL: {
    name: 'sevastopol',
    enabled: false,
    center: {
      lat: 44.6000,
      lng: 33.5333
    }
  },
  LUHANSK: {
    name: 'luhansk',
    enabled: false,
    center: {
      lat: 48.5833,
      lng: 39.3333
    }
  },
  VINNYTSIA: {
    name: 'vinnytsia',
    enabled: false,
    center: {
      lat: 49.2372,
      lng: 28.4672
    }
  },
  SIMFEROPOL: {
    name: 'simferopol',
    enabled: false,
    center: {
      lat: 44.9484,
      lng: 34.1000
    }
  },
  POLTAVA: {
    name: 'poltava',
    enabled: false,
    center: {
      lat: 49.5744,
      lng: 34.5686
    }
  },
  CHERNIHIV: {
    id: 14,
    name: 'chernihiv',
    enabled: true,
    center: {
      lat: 51.4939,
      lng: 31.2947
    }
  },
  KHERSON: {
    name: 'kherson',
    enabled: false,
    center: {
      lat: 46.6333,
      lng: 32.6000
    }
  },
  CHERKASY: {
    name: 'cherkasy',
    enabled: false,
    center: {
      lat: 49.4444,
      lng: 32.0597
    }
  },
  KHMELNYTSKYI: {
    name: 'khmelnytskyi',
    enabled: false,
    center: {
      lat: 49.4200,
      lng: 27.0000
    }
  },
  ZHYTOMYR: {
    name: 'zhytomyr',
    enabled: false,
    center: {
      lat: 50.2544,
      lng: 28.6578
    }
  },
  CHERNIVTSI: {
    name: 'chernivtsi',
    enabled: false,
    center: {
      lat: 48.2908,
      lng: 25.9344
    }
  },
  RIVNE: {
    name: 'rivne',
    enabled: false,
    center: {
      lat: 50.6197,
      lng: 26.2514
    }
  },
  SUMY: {
    name: 'sumy',
    enabled: false,
    center: {
      lat: 50.9068,
      lng: 34.7992
    }
  },
  IVANO_FRANKIVSK: {
    name: 'ivano_frankivsk',
    enabled: false,
    center: {
      lat: 48.9228,
      lng: 24.7106
    }
  },
  KREMENCHUK: {
    id: 4,
    name: 'kremenchuk',
    enabled: false,
    center: {
      lat: 49.0775,
      lng: 33.4239
    }
  },
  KROPYVNYTSKYI: {
    name: 'kropyvnytskyi',
    enabled: false,
    center: {
      lat: 48.5103,
      lng: 32.2667
    }
  },
  LUTSK: {
    name: 'lutsk',
    enabled: false,
    center: {
      lat: 50.7478,
      lng: 25.3244
    }
  },
  UZHHOROD: {
    id: 12,
    name: 'uzhhorod',
    enabled: false,
    center: {
      lat: 48.6239,
      lng: 22.2950
    }
  },
  BILA_TSERKVA: {
    id: 13,
    name: 'bilatserkv',
    enabled: true,
    center: {
      lat: 49.802615,
      lng: 30.124043
    },
    zoom: 16,
  },
  PODILSK: {
    id: 16,
    name: 'podilsk',
    enabled: true,
    center: {
      lat: 47.746189,
      lng: 29.534831
    },
    zoom: 16,
  },
  MUKACHEVE: {
    id: 17,
    name: 'mukacheve',
    enabled: true,
    center: {
      lat: 48.440841,
      lng: 22.721249
    },
    zoom: 16,
  },
  HELSINKI: {
    id: 19,
    name: 'helsinki',
    enabled: true,
    center: {
      lat: 60.1699,
      lng: 24.9384
    },
    zoom: 16,
  },
};
